<template>
    <div class="spinner"></div>
</template>

<script>
    export default {
        name: 'SpinLoader',
    };
</script>

<style>
    .spinner {
        border: 4px solid #cfd0d1;
        border-top: 4px solid white;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
