import { render, staticRenderFns } from "./TagModal.vue?vue&type=template&id=52daab7d&scoped=true&"
import script from "./TagModal.vue?vue&type=script&lang=js&"
export * from "./TagModal.vue?vue&type=script&lang=js&"
import style0 from "./TagModal.vue?vue&type=style&index=0&id=52daab7d&prod&scoped=true&lang=css&"
import style1 from "./TagModal.vue?vue&type=style&index=1&id=52daab7d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52daab7d",
  null
  
)

export default component.exports